const contentful = require("contentful");

const client = contentful.createClient({
  space: "bqwo92p1eklz",
  environment: "master", // defaults to 'master' if not set
  accessToken: "b9DGEbk3YXqGTN8r1DfywC6XqMj-4XfyeLYJUlGcDtI",
});

export default {
  listServiceStore(packages) {
    return client
      .getEntries({
        content_type: "serviceStore",
        "fields.id[in]": packages.map((p) => p.smartServiceId).join(","),
      })
      .then((response) => response.items.map((el) => el.fields))
      .catch(console.error);
  },
  listPricingCards(packages) {
    return client
      .getEntries({
        content_type: "pricingCards",
        "fields.sapNumber[in]": packages.map((p) => p.id).join(","),
      })
      .then((response) => response.items.map((el) => el))
      .catch(console.error);
  },
  getItems(smartServiceId) {
    return client
      .getEntries({
        content_type: "serviceStore",
        "fields.id[in]": smartServiceId,
      })
      .then((response) => response.items)
      .catch(console.error);
  },
};
