<template>
  <v-card>
    <v-row align="center" justify="center" style="height: 6rem">
      <v-col class="text-subtitle-2 text-center font-weight-bold my-4 mx-2">
        {{ value.name }}
      </v-col>
    </v-row>
    <div class="text-h4 text-center primary--text font-weight-black">
      {{ price }}
    </div>
    <div class="text-center">{{ value.description.split(" | ")[3] }} *</div>
    <v-divider class="mx-16 my-6"></v-divider>
    <div v-if="value.bookedQuantity > 0" class="text-center primary--text">
      {{ value.bookedQuantity }}
      {{ "Booked licences" }}
    </div>
    <div v-else class="text-center">
      {{ "No licences booked" }}
    </div>
    <v-spacer class="my-4"></v-spacer>
    <v-list>
      <v-list-item v-for="feature in value.specifications" v-bind:key="feature">
        <v-list-item-icon style="height: 3rem">
          <v-icon color="black">mdi-check</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ feature }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions class="px-8 pb-6 my-8">
      <v-spacer></v-spacer
      ><BaseDialogButton
        color="primary"
        :buttonText="'Book Package'"
        :confirmText="'Order now'"
        :dialogTitle="'Book Package'"
        width="600"
        @confirm="createSubscription()"
      >
        <template slot="content">
          <div class="px-3 py-3 mb-3" style="background: #ebecec">
            <h3>
              {{ value.name }}
              <p class="primary--text">({{ getCurrentOrganization.name }})</p>
            </h3>
            <span> {{ value.description }}</span>
            <v-row class="px-3 mt-4"
              ><v-text-field
                :label="'Price per unit'"
                color="black"
                :value="singleCosts"
                readonly
              ></v-text-field
              ><v-text-field
                :label="'Quantity'"
                v-model="quantity"
                color="black"
                type="number"
                :rules="quantityRules"
              ></v-text-field
              ><v-text-field
                :value="netCosts"
                :label="'Net'"
                color="black"
                readonly
              ></v-text-field
            ></v-row>
            <v-row class="mt-n4">
              <v-spacer />
              <v-col cols="4"
                ><p class="text-right font-weight-bold">
                  {{ totalCosts }}
                </p></v-col
              >
            </v-row>
          </div>
          <v-spacer style="height: 1rem"></v-spacer>
          <v-textarea
            filled
            v-model="internalOrderId"
            auto-grow
            rows="1"
            :label="'Internal Order Id'"
            :hint="'Reference Hint'"
            persistent-hint
            color="black"
          ></v-textarea>
          <v-spacer style="height: 2rem"></v-spacer>
          <v-row>
            <v-col cols="6">
              <strong>{{ "Billing Address" }}</strong>
              <br />
              <br />
              {{ getBillingAccount.companyName }} <br />
              {{
                [getBillingAccount.firstName, getBillingAccount.lastName].join(
                  " ",
                )
              }}<br />{{
                [
                  getBillingAccount.address.street,
                  getBillingAccount.address.houseNumber,
                ].join(" ")
              }}<br />{{
                [
                  getBillingAccount.address.postalCode,
                  getBillingAccount.address.city,
                ].join(" ")
              }}
            </v-col>
            <v-col cols="6">
              <strong>Your contract partner</strong>
              <br />
              <br />
              {{ contractPartner.name }} <br />
              {{
                [contractPartner.street, contractPartner.houseNumber].join(" ")
              }}<br />{{
                [contractPartner.zipCode, contractPartner.city].join(" ")
              }}<br />{{ [contractPartner.country].join(" ") }}
            </v-col>
          </v-row>
          <strong></strong>
          <br />
          <br />
          <v-row align="center">
            <v-col cols="1">
              <v-checkbox
                v-model="hasAgreed"
                dense
                :rules="agreementRules"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-row align="baseline">
                <div
                  v-for="(text, index) in licenceTermsWithLinks"
                  v-bind:key="index"
                >
                  <b v-if="index !== 1 && index !== 3">{{ text }}</b>
                  <a
                    href="https://proficloud.io/terms-and-conditions"
                    target="_blank"
                    v-if="index === 1"
                    >{{ text }}</a
                  >
                  <a
                    href="https://proficloud.io/software-license-terms-international"
                    target="_blank"
                    v-if="index === 3"
                    >{{ text }}</a
                  >
                  &nbsp;
                </div>
              </v-row>
            </v-col>
          </v-row>
        </template>
      </BaseDialogButton>
      <v-spacer />
    </v-card-actions>
    <v-dialog v-model="isSuccess" :width="300" style="height: 300px">
      <v-card>
        <v-card-title class="headline background">Thank you</v-card-title>
        <v-divider />
        <v-layout column justify-center align-center>
          <v-card-text> Your subscription was successfull</v-card-text>
        </v-layout>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { countries } from "../data/ContractPartners";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    imagePath: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isValid: false,
      internalOrderId: "",
      quantity: 0,
      hasAgreed: false,
      isSuccess: false,
    };
  },
  computed: {
    price() {
      let char = "";
      switch (this.value.pricing.currency) {
        case "EUR":
          char = "€";
          break;
        default:
          char = this.value.pricing.currency;
          break;
      }
      return char + " " + this.value.pricing.recurringFee;
    },
    currencySign() {
      let sign = "";
      switch (this.value.pricing.currency) {
        case "EUR":
          sign = "€";
          break;
        default:
          break;
      }
      return sign;
    },
    singleCosts() {
      return this.value.pricing.recurringFee.toFixed(2) + this.currencySign;
    },
    netCosts() {
      return (
        (this.value.pricing.recurringFee * this.quantity).toFixed(2) +
        this.currencySign
      );
    },
    totalCosts() {
      return (
        "Total costs" +
        ": " +
        (this.value.pricing.recurringFee * this.quantity).toFixed(2) +
        this.currencySign
      );
    },
    quantityRules() {
      return [(v) => !!v || "", (v) => v > 0 || ""];
    },
    agreementRules() {
      return [(v) => v || ""];
    },
    licenceTermsWithLinks() {
      return "I have read and accept the $<a>Terms and Conditions$<a> and $<a>Software License-Terms$<a> from Phoenix Contact.".split(
        "$<a>",
      );
    },
    contractPartner() {
      let shortName = this.getBillingAccount.address.country;
      let contractPartner = countries[shortName];

      return {
        name: contractPartner.subsidiary,
        street: contractPartner.street,
        houseNumber: contractPartner.houseNumber,
        city: contractPartner.city,
        zipCode: contractPartner.zipCode,
        country: contractPartner.name,
      };
    },
    ...mapGetters(["getBillingAccount", "getCurrentOrganization"]),
  },
  methods: {
    async createSubscription() {
      var bookingPackage = {
        packageId: this.value.id,
        quantity: this.quantity,
        sendNoConfirmationMail: false,
        internalOrderId: this.internalOrderId,
      };
      await this.$store.dispatch("createSubscription", bookingPackage);

      this.isSuccess = true;

      await delay(3000);

      location.reload();
    },
  },
};
</script>
