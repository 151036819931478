<template>
  <v-card
    :hover="true"
    :link="true"
    :to="$route.path + '/services?id=' + value.id"
  >
    <v-img
      :src="value.heroImage.fields.file.url"
      lazy-src="@/assets/grovez.png"
      aspect-ratio="1.7"
      cover
    >
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular
            color="grey-lighten-4"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
      <template v-slot:error>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular color="grey-lighten-4" indeterminate />
        </div>
      </template>
    </v-img>
    <v-row justify="center">
      <v-avatar
        size="128"
        color="#F5F6F6"
        style="border: black 1px solid"
        class="mt-n12 mb-4 justify-center text-center"
      >
        <v-img :src="value.icon.fields.file.url" />
      </v-avatar>
    </v-row>
    <v-card-title class="justify-center text-center text-h6 font-weight-bold">
      {{ value.pageTitle }}
    </v-card-title>
    <v-card-subtitle class="justify-center text-center text-h6">
      {{ `Starts at € ${parseInt(value.startsAt).toFixed(2)}` }}
    </v-card-subtitle>
    <v-card-text>
      <v-list v-if="value.highlights">
        <v-list-item v-for="highlight in value.highlights" :key="highlight">
          {{ highlight }}
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
};
</script>
