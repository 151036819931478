import LicenceManagement from "@/views/services/licences/LicenceManagement.vue";
import BookedLicences from "@/views/services/licences/booked/BookedLicences.vue";
import PurchaseLicences from "@/views/services/licences/purchase/PurchaseLicences.vue";
import ServiceBookingDetails from "@/views/services/licences/purchase/ServiceBookingDetails.vue";
import i18n from "@/plugins/i18n";

export default {
  licences: {
    path: "/licences",
    name: "licences",
    component: LicenceManagement,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_LicenceManagement"),
        },
      ],
    },
  },
  booked: {
    path: "/licences/booked",
    name: "booked",
    component: BookedLicences,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_LicenceManagement"),
          href: "/#/licences",
        },
        {
          name: () => "Subscriptions",
        },
      ],
    },
  },
  purchase: {
    path: "/licences/purchase",
    name: "purchase",
    component: PurchaseLicences,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_LicenceManagement"),
          href: "/#/licences",
        },
        {
          name: () => "Service Store",
        },
      ],
    },
  },
  serviceBookingDetails: {
    path: "/licences/purchase/services",
    name: "serviceBookingDetails",
    component: ServiceBookingDetails,
    meta: {
      breadcrumb: [
        {
          name: () => i18n.t("_LicenceManagement"),
          href: "/#/licences",
        },
        {
          name: () => "Service Store",
          href: "/#/licences/purchase",
        },
        {
          name: () => "Service Details",
        },
      ],
    },
  },
};
