import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import createLogger from "vuex/dist/logger";

import CustomAlarmsApi from "@/data/api/CustomAlarmsApi"; // MPDT210068-3719
import AlarmingApi from "@/data/api/AlarmingApi";
import ResponsiblesApi from "@/data/api/ResponsiblesApi";
import UsersApi from "@/data/api/UsersApi";
import OrganizationsApi from "@/data/api/OrganizationsApi";
import ServicesApi from "@/data/api/ServicesApi";
import IntegrationsApi from "@/data/api/IntegrationsApi";
import DevicesApi from "@/data/api/DevicesApi";
import SharingApi from "@/data/api/SharingApi";
import DeviceGroupsApi from "@/data/api/DeviceGroupsApi";
import SettingsApi from "@/data/api/SettingsApi";
import BillingApi from "@/data/api/BillingApi";

import i18n from "@/plugins/i18n";
import basei18n from "@smart-city-plattform/vue-component-library/src/helper/i18n";
import { parseJwt } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import router from "@/router/router";
import ContentfulApi from "@/data/external/ContentfulApi";

Vue.use(Vuex);

const logger = createLogger({
  collapsed: true, // auto-expand logged mutations
  //filter(mutation, stateBefore, stateAfter) {
  filter(mutation) {
    // returns `true` if a mutation should be logged
    // `mutation` is a `{ type, payload }`
    return mutation.type !== "aBlacklistedMutation";
  },
  logActions: false, // Log Actions
  logMutations: false, // Log mutations
  logger: console, // implementation of the `console` API, default `console`
});

function objectifyArrayOnId(arr) {
  return arr.reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {});
}

function copySet(obj, subobj) {
  let copy = Object.assign({}, obj);
  copy[subobj.id] = subobj;
  return copy;
}

function copyDelete(obj, subobj) {
  let copy = Object.assign({}, obj);
  delete copy[subobj.id];
  return copy;
}

export default new Vuex.Store({
  plugins: [logger],
  state: {
    // Users
    currentUser: null,

    // Organizations
    organizations: {},
    currentOrganization: {},

    // Settings
    version: "0.0.0.1",

    // Preferences
    locale: "en",

    users: [],
    roles: [],
    grants: [],

    // Services
    services: {},

    // Devices and Data
    integrations: {},
    devices: {},
    deviceGroups: {},
    diagnosis: [],
    supportedTypes: [],
    availableCommands: {},

    // Sharings
    sharings: {},

    // Alarms
    alarms: {},
    responsibles: {},
    // MPDT210068-3719
    customAlarms: {},

    // Error
    errorFlag: false,
    errorTitle: "Oops, something went wrong!",
    errorText: "Please try again later or contact your service admin",

    // Billing Data
    billingToken: {},
    billingAccount: {},
    subscriptions: {},
    packages: [],
    licenceBundle: {},
    serviceStore: [],
  },

  mutations: {
    // Users
    setCurrentUser(state, user) {
      state.currentUser = user;
    },

    setRoles(state, roles) {
      state.roles = roles;
    },

    setGrants(state, grants) {
      state.grants = grants;
    },

    addGrant(state, grant) {
      if (
        state.grants.some((g) => g.id === grant.id && g.role === grant.role)
      ) {
        return;
      }
      state.grants = state.grants.concat(grant);
    },

    addGrants(state, grants) {
      state.grants = state.grants.concat(grants);
    },

    removeGrant(state, grant) {
      state.grants = state.grants.filter(
        (g) => !(g.id === grant.user.id && g.role === grant.role),
      );
    },

    // Organizations
    setCurrentOrganization(state, organization) {
      state.currentOrganization = organization;
      localStorage.setItem("organizationId", organization.id);
    },

    setOrganizations(state, organizations) {
      state.organizations = objectifyArrayOnId(organizations);
    },

    setOrganization(state, organization) {
      state.organizations = copySet(state.organizations, organization);
    },

    addOrganization(state, organization) {
      state.organizations[organization.id] = organization;
    },

    deleteOrganization(state, id) {
      state.organizations = copyDelete(
        state.organizations,
        state.organizations[id],
      );
    },

    // Services
    setServices(state, services) {
      state.services = objectifyArrayOnId(services);
    },

    setService(state, service) {
      state.services = copySet(state.services, service);
    },

    addService(state, service) {
      state.services[service.id] = service;
    },

    deleteService(state, id) {
      state.services = copyDelete(state.services, state.services[id]);
    },

    // Integrations
    setIntegrations(state, integrations) {
      state.integrations = objectifyArrayOnId(integrations);
    },

    setIntegration(state, integration) {
      state.integrations = copySet(state.integrations, integration);
    },

    addIntegration(state, integration) {
      state.integrations[integration.id] = integration;
    },

    deleteIntegration(state, id) {
      state.integrations = copyDelete(
        state.integrations,
        state.integrations[id],
      );
    },

    // Settings
    setVersion(state, version) {
      state.version = version;
    },

    setLocale(state, locale) {
      i18n.locale = locale;
      basei18n.locale = locale;
      state.locale = locale;
    },

    // Devices
    setDevices(state, devices) {
      state.devices = objectifyArrayOnId(devices);
    },

    setDevice(state, device) {
      state.devices = copySet(state.devices, device);
    },

    addDevices(state, devices) {
      state.devices = { ...state.devices, ...objectifyArrayOnId(devices) };
    },

    deleteDevice(state, id) {
      state.devices = copyDelete(state.devices, state.devices[id]);
    },

    setDeviceGroups(state, deviceGroups) {
      state.deviceGroups = objectifyArrayOnId(deviceGroups);
    },

    setDeviceGroup(state, deviceGroup) {
      state.deviceGroups = copySet(state.deviceGroups, deviceGroup);
    },

    addDeviceGroup(state, props) {
      state.deviceGroups[props.id] = props;
    },

    deleteDeviceGroup(state, id) {
      state.deviceGroups = copyDelete(
        state.deviceGroups,
        state.deviceGroups[id],
      );
    },
    // Sharings
    setSharings(state, sharings) {
      state.sharings = objectifyArrayOnId(sharings);
    },
    setSharing(state, sharing) {
      state.sharings = copySet(state.sharings, sharing);
    },
    deleteSharing(state, id) {
      state.sharings = copyDelete(state.sharings, state.sharings[id]);
    },

    // Diagnosis
    setDiagnosis(state, diagnosis) {
      state.diagnosis = diagnosis;
    },

    // MPDT210068-3719
    setCustomAlarms(state, customAlarms) {
      state.customAlarms = objectifyArrayOnId(customAlarms);
    },

    setCustomAlarm(state, customAlarm) {
      state.customAlarms = copySet(state.customAlarms, customAlarm);
    },

    addCustomAlarm(state, props) {
      state.customAlarms[props.id] = props;
    },

    deleteCustomAlarm(state, id) {
      state.customAlarms = copyDelete(
        state.customAlarms,
        state.customAlarms[id],
      );
    },

    // Alarms
    setAlarms(state, alarms) {
      state.alarms = objectifyArrayOnId(alarms);
    },

    setAlarm(state, alarm) {
      state.alarms = copySet(state.alarms, alarm);
    },

    addAlarm(state, props) {
      state.alarms[props.id] = {
        id: props.id,
        name: props.name,
        applies: props.applies,
        rules: props.rules,
      };
    },

    deleteAlarm(state, id) {
      state.alarms = copyDelete(state.alarms, state.alarms[id]);
    },

    deleteAlarms(state, ids) {
      ids.forEach((id) => {
        state.alarms = copyDelete(state.alarms, state.alarms[id]);
      });
    },

    // Responsibles
    setResponsibles(state, responsibles) {
      state.responsibles = objectifyArrayOnId(responsibles);
    },

    setResponsible(state, responsible) {
      state.responsibles = copySet(state.responsibles, responsible);
    },

    addResponsible(state, responsible) {
      state.responsibles = copySet(state.responsibles, responsible);
    },

    deleteResponsible(state, id) {
      state.responsibles = copyDelete(
        state.responsibles,
        state.responsibles[id],
      );
    },

    // Billing

    setBillingAccessToken(state, billingToken) {
      state.billingToken = billingToken;
      localStorage.setItem("billing-token", JSON.stringify(billingToken));
    },

    setBillingAccount(state, billingAccount) {
      state.billingAccount = billingAccount;
    },

    setSubscriptions(state, subscriptions) {
      state.subscriptions = objectifyArrayOnId(subscriptions);
    },

    setSubscription(state, subscription) {
      state.subscriptions = copySet(state.subscriptions, subscription);
    },

    addSubscription(state, subscription) {
      state.subscriptions[subscription.id] = subscription;
    },

    setPackages(state, packages) {
      state.packages = packages;
    },

    setLicenceBundle(state, licenceBundle) {
      state.licenceBundle = licenceBundle;
    },

    setServiceStore(state, serviceStore) {
      state.serviceStore = serviceStore;
    },

    // Supported Device Types
    setSupportedDeviceTypes(state, supportedTypes) {
      state.supportedTypes = supportedTypes;
    },

    // Available Device Commands
    setAvailableDeviceCommands(state, availableCommands) {
      state.availableCommands = availableCommands;
    },

    // Error
    setErrorFlag(state, errorFlag) {
      state.errorFlag = errorFlag;
    },

    setErrorTitle(state, errorTitle) {
      state.errorTitle = errorTitle;
    },

    setErrorText(state, errorText) {
      state.errorText = errorText;
    },
  },
  actions: {
    // Users
    async loadGroupRoles({ commit }, path) {
      await UsersApi.listRolesByGroup(path)
        .then((r) => r.data)
        .then((roles) => {
          commit("setRoles", roles);
        });
    },

    async loadGroupGrants({ commit, state }, path) {
      commit("setGrants", []);
      for (let i = 0; state.roles.length > i; i++) {
        await UsersApi.getAllUsersByGroup(path + "/" + state.roles[i])
          .then((r) => r.data)
          .then((usersInGroup) => {
            let grants = usersInGroup.map((user) =>
              Object.assign({ role: state.roles[i] }, user),
            );
            commit("addGrants", grants);
          });
      }
    },

    async addUserToGroup({ commit }, { user, role, group }) {
      await UsersApi.addUserToGroup([group, role].join("/"), user.username)
        .then((r) => r.data)
        .then((addedUser) => {
          commit("addGrant", {
            id: addedUser.id,
            role: role,
            username: addedUser.username,
          });
        });
    },

    async removeUserFromGroup({ commit }, { user, role, group }) {
      await UsersApi.removeUserFromGroup(
        [group, role].join("/"),
        user.username,
      );
      commit("removeGrant", { user, role, group });
    },

    // Organizations
    async createOrganization({ commit }, organization) {
      return await OrganizationsApi.createOrganization(organization)
        .then((r) => r.data)
        .then((o) => {
          o.type = "organization";
          commit("addOrganization", o);
          return o;
        });
    },

    async updateCurrentOrganization({ commit }, newOrganization) {
      commit("setBillingAccessToken", {});
      commit("setSubscriptions", []);
      commit("setPackages", []);
      commit("setBillingAccount", {});
      commit("setCurrentOrganization", newOrganization);
    },

    async updateOrganization({ commit }, updateOrganization) {
      return await OrganizationsApi.updateOrganization(updateOrganization)
        .then((r) => r.data)
        .then((update) => {
          commit("setOrganization", update);
          return update;
        });
    },

    async loadOrganizations({ commit, state }) {
      // Load all organizations
      return await OrganizationsApi.listOrganizations()
        .then((r) => r.data)
        .then((organizations) => {
          commit("setOrganizations", organizations);
          return organizations;
        })
        .then((organizations) => {
          try {
            if (
              organizations.some(
                (o) => o.id === router.currentRoute.query.organization_id,
              )
            ) {
              commit(
                "setCurrentOrganization",
                state.organizations[router.currentRoute.query.organization_id],
              );
              return;
            }
          } catch (e) {
            console.log(e);
          }

          if (localStorage.getItem("organizationId") === undefined)
            return commit("setCurrentOrganization", {});

          const currentOrganizationId = localStorage.getItem("organizationId");
          if (organizations.some((o) => o.id === currentOrganizationId)) {
            commit(
              "setCurrentOrganization",
              state.organizations[currentOrganizationId],
            );
          } else {
            commit("setCurrentOrganization", {});
          }
        });
    },

    async loadOrganization({ commit }, id) {
      return await OrganizationsApi.getOrganization(id)
        .then((r) => r.data)
        .then((o) => {
          commit("setOrganization", o);
          return o;
        });
    },

    async removeOrganization({ commit, getters }, organization) {
      return await OrganizationsApi.deleteOrganization(organization).then(
        () => {
          commit("deleteOrganization", organization.id);
          if (getters.getCurrentOrganization.id === organization.id) {
            commit("setCurrentOrganization", {});
          }
        },
      );
    },

    // Services

    async loadServices({ commit }) {
      return await ServicesApi.listServices()
        .then((r) => r.data)
        .then((services) => {
          return services.map((s) => {
            return Object.assign({}, s);
          });
        })
        // Set services
        .then((services) => {
          commit("setServices", services);
          return services;
        });
    },
    async loadService({ commit }, id) {
      return await ServicesApi.getService(id)
        .then((r) => r.data)
        // Set services
        .then((service) => {
          commit("setService", service);
          return service;
        });
    },

    async createService({ commit }, { service, activationOption }) {
      const serv = await ServicesApi.createService(service)
        .then((r) => r.data)
        .then((newService) => {
          newService.type = "service";
          commit("addService", newService);
          return newService;
        });

      await ServicesApi.setActivationService(serv, activationOption)
        .then((r) => r.data)
        .then((updateService) => {
          updateService.type = "service";
          commit("setService", updateService);
          return updateService;
        });
    },

    async activateService({ commit }, { service, activationOption }) {
      await ServicesApi.setActivationService(service, activationOption)
        .then((r) => r.data)
        .then((updateService) => {
          updateService.type = "service";
          commit("setService", updateService);
          return updateService;
        });
    },

    async updateService({ commit }, service) {
      await ServicesApi.updateService(service)
        .then((r) => r.data)
        .then((updateService) => {
          updateService.type = "service";
          commit("setService", updateService);
          return updateService;
        });
    },

    async removeService({ commit }, service) {
      return await ServicesApi.deleteService(service).then(() => {
        commit("deleteService", service.id);
      });
    },

    // Settings
    async loadVersion({ commit }) {
      await SettingsApi.getVersion().then((version) => {
        commit(
          "setVersion",
          `BE: ${version.data} / FE: ${process.env.VUE_APP_GIT_TAG}`,
        );
      });
    },

    // Preferences

    async loadLocale({ commit }) {
      if (!localStorage.getItem("locale")) {
        localStorage.setItem("locale", "en");
      }
      let locale = localStorage.getItem("locale");
      commit("setLocale", locale);
    },

    async setLocale({ commit }, locale) {
      localStorage.setItem("locale", locale);
      commit("setLocale", locale);
    },

    // Integrations
    async createIntegration({ commit }, integration) {
      return await IntegrationsApi.createIntegration(integration)
        .then((r) => r.data)
        .then((created) => {
          commit("addIntegration", created);
          return created;
        });
    },

    async loadIntegration({ commit }, integrationId) {
      return await IntegrationsApi.getIntegration(integrationId)
        .then((r) => r.data)
        .then((integration) => {
          commit("setIntegration", integration);
          return integration;
        });
    },

    async loadIntegrations({ commit }) {
      return await IntegrationsApi.listIntegrations()
        .then((r) => r.data)
        // Set integrations
        .then((integrations) => {
          commit("setIntegrations", integrations);
          return integrations;
        });
    },

    async updateIntegration({ commit }, integrationUpdate) {
      return await IntegrationsApi.updateIntegration(integrationUpdate)
        .then((r) => r.data)
        .then((update) => {
          commit("setIntegration", update);
          return update;
        });
    },

    async deleteIntegration({ commit }, integration) {
      return await IntegrationsApi.deleteIntegration(integration).then(() => {
        return commit("deleteIntegration", integration.id);
      });
    },

    // Devices
    async loadDevice({ commit }, id) {
      return await DevicesApi.getDevice(id)
        .then((r) => r.data)
        .then((device) => {
          commit("setDevice", device);
          return device;
        });
    },
    async loadDevices({ commit }) {
      return await DevicesApi.listDevices()
        .then((r) => r.data)
        .then((devices) => {
          commit("setDevices", devices);
          return devices;
        });
    },

    async loadSupportedDeviceTypes({ commit }) {
      return await DevicesApi.listSupportedDeviceTypes()
        .then((r) => r.data)
        .then((types) => {
          commit("setSupportedDeviceTypes", types);
          return types;
        });
    },

    async loadAvailableDeviceCommands({ commit }, details) {
      return await DevicesApi.getAvailableDeviceCommands(details)
        .then((r) => r.data)
        .then((availableCommands) => {
          commit("setAvailableDeviceCommands", availableCommands);
          return availableCommands;
        });
    },

    async onboardDevices({ commit }, deviceBulk) {
      return await DevicesApi.onboardDevices(deviceBulk)
        .then((r) => r.data)
        .then(() => commit("addDevices", deviceBulk.deviceList));
    },

    async activateDevices({ dispatch }, { devices, activationOption }) {
      return await DevicesApi.activateDevices(
        devices.map((d) => d.id),
        activationOption,
      )
        .then((r) => r.data)
        .then(() => dispatch("loadDevices"));
    },

    async updateDevice({ commit }, deviceUpdate) {
      return await DevicesApi.updateDevice(deviceUpdate)
        .then((r) => r.data)
        .then((update) => {
          commit("setDevice", update);
          return update;
        });
    },

    async deleteDevice({ commit }, device) {
      return await DevicesApi.deleteDevice(device).then(() => {
        return commit("deleteDevice", device.id);
      });
    },

    // Sharings
    async loadSharings({ commit }) {
      return await SharingApi.listSharing().then((r) => {
        commit("setSharings", r.data.result);
        return r.data.result;
      });
    },

    async loadSharingWithId({ commit }, id) {
      return await SharingApi.getSharing(id).then((r) => {
        commit("setSharing", r.data.result);
        return r.data.result;
      });
    },

    async saveSharing({ commit }, payload) {
      return await SharingApi.createSharing(payload).then(() => {
        commit("setSharing", payload);
      });
    },

    async removeSharing({ commit }, sharing) {
      return await SharingApi.deleteSharing(sharing.id).then(() => {
        commit("deleteSharing", sharing.id);
      });
    },

    // Device Groups
    async createDeviceGroup({ commit }, deviceGroup) {
      return await DeviceGroupsApi.createDeviceGroup(deviceGroup)
        .then((r) => r.data)
        .then((created) => {
          commit("addDeviceGroup", created);
          return created;
        });
    },
    async loadDeviceGroup({ commit }, id) {
      return await DeviceGroupsApi.getDeviceGroup(id)
        .then((r) => r.data)
        .then((deviceGroup) => {
          commit("setDeviceGroup", deviceGroup);
          return deviceGroup;
        });
    },
    async loadDeviceGroups({ commit }) {
      return await DeviceGroupsApi.listDeviceGroups()
        .then((r) => r.data)
        .then((deviceGroups) => {
          commit("setDeviceGroups", deviceGroups);
          return deviceGroups;
        });
    },

    async updateDeviceGroup({ commit }, deviceGroup) {
      return await DeviceGroupsApi.updateDeviceGroup(deviceGroup)
        .then((r) => r.data)
        .then((update) => {
          commit("setDeviceGroup", update);
          return update;
        });
    },
    async deleteDeviceGroup({ commit }, deviceGroup) {
      return await DeviceGroupsApi.deleteDeviceGroup(deviceGroup).then(() => {
        commit("deleteDeviceGroup", deviceGroup.id);
      });
    },

    // Diagnosis
    async loadDiagnosis({ commit }, id) {
      return await DevicesApi.getDeviceDiagnosis(id)
        .then((r) => r.data)
        .then((diagnosis) => {
          commit(
            "setDiagnosis",
            diagnosis.map((d) => ({
              value: d.value,
              timestamp: d.time.epochSecond * 1000,
            })),
          );
          return diagnosis;
        });
    },

    // MPDT210068-3719: External Alarm. - Done AW
    async loadCustomAlarms({ commit }) {
      return await CustomAlarmsApi.listCustomAlarms()
        .then((r) => r.data)
        .then((customAlarms) => {
          commit("setCustomAlarms", customAlarms);
          return customAlarms;
        });
    },

    async loadCustomAlarm({ commit }, id) {
      return await CustomAlarmsApi.getCustomAlarm(id)
        .then((r) => r.data)
        .then((customAlarm) => {
          commit("setCustomAlarm", customAlarm);
          return customAlarm;
        });
    },

    async createNewCustomAlarm({ commit }, customAlarm) {
      return await CustomAlarmsApi.createCustomAlarm(customAlarm)
        .then((r) => r.data)
        .then((created) => {
          commit("setCustomAlarm", created);
          return created;
        });
    },

    async updateCustomAlarm({ commit }, customAlarmUpdate) {
      return await CustomAlarmsApi.updateCustomAlarm(customAlarmUpdate)
        .then((r) => r.data)
        .then((update) => {
          commit("setCustomAlarm", update);
          return update;
        });
    },

    async deleteCustomAlarm({ commit }, customAlarm) {
      return await CustomAlarmsApi.deleteCustomAlarm(customAlarm).then(() => {
        return commit("deleteCustomAlarm", customAlarm.id);
      });
    },

    // Alarming
    async createTestAlarm({ commit }, alarm) {
      return await AlarmingApi.createTestAlarm(alarm)
        .then((r) => r.data)
        .then((created) => {
          commit("setAlarm", created);
          return created;
        });
    },
    async loadAlarm({ commit }, id) {
      return await AlarmingApi.getAlarm(id)
        .then((r) => r.data)
        .then((alarm) => {
          commit("setAlarm", alarm);
          return alarm;
        });
    },
    async loadAlarms({ commit }) {
      return await AlarmingApi.listAlarms()
        .then((r) => r.data)
        .then((alarms) => {
          commit("setAlarms", alarms);
          return alarms;
        });
    },

    async updateAlarm({ commit }, alarm) {
      return await AlarmingApi.updateAlarm(alarm)
        .then((r) => r.data)
        .then((alarm) => {
          commit("setAlarm", alarm);
          return alarm;
        });
    },
    async deleteAlarm({ commit }, alarm) {
      return await AlarmingApi.deleteAlarm(alarm).then(() => {
        return commit("deleteAlarm", alarm.id);
      });
    },

    async deleteAlarms({ commit }, alarmIds) {
      return await AlarmingApi.deleteAlarms(alarmIds).then(() => {
        return commit("deleteAlarms", alarmIds);
      });
    },

    // Responsibles
    async createResponsible({ commit }, responsible) {
      return await ResponsiblesApi.createResponsible(responsible)
        .then((r) => r.data)
        .then((created) => {
          commit("addResponsible", created);
          return created;
        });
    },
    async loadResponsibles({ commit }) {
      return await ResponsiblesApi.listResponsibles()
        .then((r) => r.data)
        .then((responsibles) => {
          commit("setResponsibles", responsibles);
          return responsibles;
        });
    },

    async loadResponsible({ commit }, id) {
      return await ResponsiblesApi.getResponsible(id)
        .then((r) => r.data)
        .then((responsible) => {
          commit("addResponsible", responsible);
          return responsible;
        });
    },

    async updateResponsible({ commit }, responsible) {
      return await ResponsiblesApi.updateResponsible(responsible)
        .then((r) => r.data)
        .then((update) => {
          commit("setResponsible", update);
          return update;
        });
    },

    async deleteResponsible({ dispatch }, responsible) {
      return await ResponsiblesApi.deleteResponsible(responsible).then(() => {
        return dispatch("loadResponsibles");
      });
    },

    // Billing

    async loadBillingAccessToken({ commit }) {
      try {
        const billingToken = JSON.parse(localStorage.getItem("billing-token"));
        commit("setBillingAccessToken", billingToken);
      } catch {
        commit("setBillingAccessToken", {});
      }
    },

    async loginBilling({ commit, dispatch }, { username, password }) {
      await BillingApi.login(username, password)
        .then((r) => r.data)
        .then((token) => {
          commit("setBillingAccessToken", token);
        });

      await dispatch("loadBillingAccount");
      await dispatch("loadSubscriptions");
      await dispatch("loadPackages");
    },

    async loadBillingAccount({ commit, getters }) {
      if (!getters.hasValidBillingToken) {
        commit("setBillingAccount", {});
        return;
      }
      return await BillingApi.getBillingAccount()
        .then((r) => r.data)
        .then((account) => {
          commit("setBillingAccount", account);
          return account;
        });
    },

    async updateBillingAccount({ commit, getters }, billingAccount) {
      if (!getters.hasValidBillingToken) {
        return;
      }
      return await BillingApi.updateBillingAccount(billingAccount)
        .then((r) => r.data)
        .then((account) => {
          commit("setBillingAccount", account);
          return account;
        });
    },

    // Subscriptions
    async createSubscription({ commit, getters }, bookingPackage) {
      if (!getters.hasValidBillingToken) {
        return;
      }
      return await BillingApi.createSubscription(bookingPackage)
        .then((r) => r.data)
        .then((created) => {
          commit("addSubscription", created);
          return created;
        });
    },

    async loadSubscriptions({ commit, getters }) {
      if (!getters.hasValidBillingToken) {
        commit("setSubscriptions", []);
        return;
      }
      return await BillingApi.listSubscriptions()
        .then((r) => r.data)
        // Set subscriptions
        .then((subscriptions) => {
          commit("setSubscriptions", subscriptions);
          return subscriptions;
        });
    },

    async cancelSubscription({ commit, getters }, subscription) {
      if (!getters.hasValidBillingToken) {
        return;
      }
      return await BillingApi.cancelSubscription(subscription)
        .then((r) => r.data)
        .then((canceled) => {
          commit("setSubscription", canceled);
          return canceled;
        });
    },

    async loadPackages({ commit, getters }) {
      if (!getters.hasValidBillingToken) {
        commit("setPackages", []);
        return;
      }
      return await BillingApi.listAvailablePackages()
        .then((r) => r.data)
        .then((packages) => {
          commit("setPackages", packages);
          return packages;
        });
    },
    async loadLicenceBundle({ commit }) {
      return await BillingApi.getLicenceBundle()
        .then((r) => r.data)
        .then((licenceBundle) => {
          commit("setLicenceBundle", licenceBundle);
          return licenceBundle;
        });
    },

    async loadServiceStoreData({ commit, getters }) {
      return await ContentfulApi.listServiceStore(getters.getPackages).then(
        (data) => {
          commit("setServiceStore", data);
          return data;
        },
      );
    },
  },
  getters: {
    // Users
    getCurrentUser() {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.preferred_username;
    },
    getKcGroups() {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups;
    },
    isSystemAdmin() {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.includes("/priamos/ADMIN");
    },
    isOrganizationAdmin() {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.some(
        (el) =>
          el.includes("/organizations/") &&
          el.includes("/ADMIN") &&
          el.split("/").length === 4,
      );
    },
    isServiceUser() {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.some((el) => el.includes("/services/"));
    },

    isCurrentOrganizationsViewer: (state) => {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.some(
        (el) =>
          el.includes("/organizations/") &&
          el.includes("/VIEWER") &&
          el.includes(state.currentOrganization.id) &&
          el.split("/").length === 4,
      );
    },
    isCurrentOrganizationsAdmin: (state) => {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.some(
        (el) =>
          el.includes("/organizations/") &&
          el.includes("/ADMIN") &&
          el.includes(state.currentOrganization.id) &&
          el.split("/").length === 4,
      );
    },
    isServiceAdmin() {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.some(
        (el) =>
          el.includes("/services/") &&
          el.includes("/ADMIN") &&
          el.split("/").length === 4,
      );
    },
    isServiceAdminOf(id) {
      const parts = localStorage.getItem("pxce-token").split(".");
      const content = JSON.parse(Buffer.from(parts[1], "base64").toString());
      return content.groups.some((el) =>
        el.includes("/services/" + id + "/ADMIN"),
      );
    },

    getAllUsers: (state) => {
      return state.users;
    },
    getAllRoles: (state) => {
      return state.roles;
    },

    getAllGrants: (state) => {
      return state.grants;
    },

    // Organizations
    getOrganizations: (state) => {
      return Object.values(state.organizations);
    },
    getCurrentOrganization: (state) => {
      return state.currentOrganization;
    },

    getOrganizationById: (state) => (id) => {
      return state.organizations[id];
    },

    // Services
    getServices: (state) => {
      return Object.values(state.services);
    },

    getServicesByOrganizationId: (state) => (id) => {
      return Object.values(state.services).filter(
        (service) => service.organizationId === id,
      );
    },

    getServiceById: (state) => (id) => {
      return state.services[id];
    },

    // Integration
    getAllIntegrations: (state) => {
      return Object.values(state.integrations);
    },
    getIntegrationById: (state) => (id) => {
      return state.integrations[id];
    },
    getIntegrationsByOrganizationId: (state) => (id) => {
      return Object.values(state.integrations).filter(
        (integration) => integration.organizationId === id,
      );
    },
    getAllDevices: (state) => {
      return Object.values(state.devices);
    },

    getSupportedDeviceTypes: (state) => {
      return state.supportedTypes;
    },

    getAvailableDeviceCommands: (state) => {
      return state.availableCommands;
    },

    getDeviceById: (state) => (id) => {
      return state.devices[id];
    },

    getAllDeviceGroups: (state) => {
      return Object.values(state.deviceGroups);
    },

    getDeviceGroupById: (state) => (id) => {
      return state.deviceGroups[id];
    },

    // Sharings
    getAllSharings: (state) => {
      return Object.values(state.sharings);
    },

    getSharingById: (state) => (id) => {
      return state.sharings[id];
    },

    // Diagnosis
    getAllDiagnosis: (state) => {
      return state.diagnosis;
    },

    // Settings
    getLocale: (state) => {
      return state.locale;
    },

    getVersion: (state) => {
      return state.version;
    },

    // MPDT210068-3719: External Alarm. - Done AW
    getAllCustomAlarms: (state) => {
      return Object.values(state.customAlarms);
    },

    getCustomAlarmById: (state) => (id) => {
      return state.customAlarms[id];
    },

    // Alarming - Alarms
    getAllAlarms: (state) => {
      return Object.values(state.alarms);
    },

    getAlarmById: (state) => (id) => {
      return state.alarms[id];
    },

    // Alarming - Responsibles
    getAllResponsibles: (state) => {
      return Object.values(state.responsibles);
    },
    getResponsibleById: (state) => (id) => {
      return state.responsibles[id];
    },

    // Error
    getErrorFlag: (state) => {
      return state.errorFlag;
    },

    getErrorTitle: (state) => {
      return state.errorTitle;
    },

    getErrorText: (state) => {
      return state.errorText;
    },

    // Billing Data

    getBillingAccount: (state) => {
      return state.billingAccount;
    },

    getBillingAccessToken: (state) => {
      return state.billingToken;
    },

    hasValidBillingToken: (state) => {
      if (state.billingToken == {}) {
        return false;
      }
      try {
        const now = moment.now();
        const exp = parseJwt(state.billingToken.access_token).exp * 1000;
        return now <= exp;
      } catch (error) {
        return false;
      }
    },
    // Subscriptions
    getAllSubscriptions: (state) => {
      return Object.values(state.subscriptions);
    },
    getSubscriptionById: (state) => (id) => {
      return state.subscriptions[id];
    },
    getPackages: (state) => {
      return state.packages;
    },
    getLicenceBundle: (state) => {
      return state.licenceBundle;
    },
    getServiceStore: (state) => {
      return state.serviceStore;
    },
    getServiceStoreBySmartServiceId: (state) => (id) => {
      return objectifyArrayOnId(state.serviceStore)[id];
    },
  },
});
