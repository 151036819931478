<template>
  <BaseContentLoader :title="'Service Store'" :isLoading="isLoading">
    <template slot="content">
      <v-row>
        <v-col
          v-for="(service, ci) in getServiceStore"
          cols="4"
          :key="`${ci}-service-card`"
        >
          <ServiceCard :value="service" />
        </v-col>
      </v-row>
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";
import ServiceCard from "@/components/ServiceCard.vue";

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  components: {
    ServiceCard,
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadPackages");
      await this.$store.dispatch("loadServiceStoreData");
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters(["getServiceStore"]),
  },
};
</script>
