<template>
  <BaseContentLoader :title="'Service Details'" :isLoading="isLoading">
    <template slot="content">
      <v-row class="px-3 py-4 align-center">
        <v-avatar :size="152">
          <v-img contain :src="serviceDetails?.icon.fields.file.url" />
        </v-avatar>
        <v-col cols="8">
          <p class="text-h5 font-weight-bold">
            {{ serviceDetails?.pageTitle }}
          </p>
          <p>{{ serviceDetails?.serviceDescription }}</p>
          <div class="text-h6 font-weight-bold">
            {{ `Starts at € ${parseInt(serviceDetails?.startsAt).toFixed(2)}` }}
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-card-title>Pricing</v-card-title>
      </v-row>

      <v-row>
        <v-col
          v-for="(item, ii) in packageItems"
          cols="3"
          :key="`${ii}-item-card`"
        >
          <PackageCard :value="item" />
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <span
        >Excluding taxes. Prices are displayed in euros. During checkout,
        amounts may be shown in your local currency. Check out this
        <a
          href="https://proficloud.io/knowledge-hub/faqs/service-store/which-countries-are-available-in-the-service-store"
          target="_blank"
        >
          FAQ
        </a>
        article to see if your country is available for the Service Store</span
      >
    </template>
  </BaseContentLoader>
</template>

<script>
import { mapGetters } from "vuex";

import { cloneDeep } from "lodash";
import { delay } from "@smart-city-plattform/vue-component-library/src/helper/helper";
import PackageCard from "@/components/PackageCard.vue";

export default {
  data() {
    return {
      isLoading: true,
      newBillingAccount: {},
      category: {},
    };
  },
  components: {
    PackageCard,
  },
  async mounted() {
    try {
      await this.$store.dispatch("loadPackages");
      await this.$store.dispatch("loadSubscriptions");
      await this.$store.dispatch("loadServiceStoreData");

      this.newBillingAccount = cloneDeep(
        await this.$store.dispatch("loadBillingAccount"),
      );
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
  computed: {
    ...mapGetters([
      "getCurrentOrganization",
      "getPackages",
      "getAllSubscriptions",
      "getBillingAccount",
      "hasValidBillingToken",
      "getServiceStoreBySmartServiceId",
    ]),
    serviceDetails() {
      return this.getServiceStoreBySmartServiceId(this.$route.query.id);
    },

    packageItems() {
      var that = this;
      let licences = {};
      let packageIdsSet = [...new Set(that.getPackages.map((p) => p.id))];

      packageIdsSet.forEach((packageId) => {
        let quantity = 0;
        let subscriptionsByPackageId = that.getAllSubscriptions.filter(
          (s) => s.bookedPackageId === packageId,
        );
        subscriptionsByPackageId.forEach((s) => {
          quantity = quantity + s.quantity;
        });
        licences[packageId] = quantity;
      });

      let packageItems = this.getPackages
        .filter((p) => p.smartServiceId === this.$route.query.id)
        .map((p) => {
          var obj = { ...p };
          obj.bookedQuantity = licences[p.id];
          obj.specifications = that.serviceDetails?.planVariants
            .map((el) => el.fields)
            .find((el) => el.sapNumber === p.id).specifications;

          return obj;
        });

      packageItems.sort((a, b) => parseInt(b.id) - parseInt(a.id));

      return packageItems;
    },
  },
  methods: {
    async updateBillingAccount() {
      this.isLoading = true;
      try {
        await delay(500);
        await this.$store.dispatch(
          "updateBillingAccount",
          this.newBillingAccount,
        );
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>
