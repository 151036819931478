<template>
  <v-row justify="center">
    <template v-if="!hasValidBillingToken">
      <BaseProficloudLogin class="pt-8" />
    </template>
    <template v-else>
      <BaseTile :title="'Subscriptions'" path="/licences/booked" />
      <BaseTile :title="$t('Service Store')" path="/licences/purchase" />
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["hasValidBillingToken"]),
  },
};
</script>
